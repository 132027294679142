import logo from './logo.svg';
import './App.css';
import MenuModal from './MenuModal'
import { ApolloProvider, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_API_URL
  }),
  cache: new InMemoryCache()
})

function MenuModals() {
  return (
    <ApolloProvider client={client}>
      <MenuModal />
    </ApolloProvider>
  );
}

export default MenuModals;
