import logo from './logo.svg';
import './App.css';
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider, gql, useQuery } from '@apollo/client'
import Menu from './Menu'

const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_API_URL
  }),
  cache: new InMemoryCache()
})

function App() {
  return (
    <ApolloProvider client={client}>
      <Menu />
    </ApolloProvider>
  );
}

export default App;
