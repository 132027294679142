import {gql} from '@apollo/client'

const query = gql`query {
  allMenuItems {
    title
    description
    category
    quantity
    msrp
    wholesale
    full{
      publicUrl
    }
    closeup {
      publicUrl
    }
  }
}`

export default query 