import React, {useState} from 'react'
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider, gql, useQuery } from '@apollo/client'
import ReactLoading from 'react-loading'
import MenuQuery from './MenuQuery'


const Menu = (props) => {
  const {loading, data} = useQuery(MenuQuery)
  const [init, setInit] = useState(null)  
  const [menuItems, setMenuItems] = useState(null)

  if(loading) {
    <ReactLoading type="spin" color="#ccc" height={150} width={275} />
  }

  // const getMenuItems = async () => {
  //   fetch()
  //   .then(res=>res.json()
  //   .then(json=>setMenuItems(json)))
  // }

  // if(null === init) {
  //   console.log(`pop`)
  //   setInit(true)
  //   getMenuItems()
  // }

  return  <div className="row">   
            {/* MAP THUMBS */}
            { data ? data.allMenuItems.map((item, index)=>(
              <div key={item.title} className="col-lg-4 col-sm-6 mb-4">
                  <div className="portfolio-item">
                      <a className="portfolio-link" data-toggle="modal" href={"#portfolioModal"+index}>
                          <div className="portfolio-hover">
                              <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                          </div>
                            
                          { item.closeup ? (
                            <img className="img-fluid" src={`${item.closeup.publicUrl}`} alt={item.title} />
                          ) : item.full ? (
                            <img className="img-fluid" src={`${(item.full.publicUrl)}`} alt={item.title} />
                          ) : null }
                          
                      </a>
                      <div className="portfolio-caption">
                          <div className="portfolio-caption-heading">{item.title}</div>
                          <div className="portfolio-caption-subheading text-muted">{item.category}</div>
                      </div>
                  </div>
              </div>
            )) : null }
          </div>
}

export default Menu