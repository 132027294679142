import React, {useState} from 'react'
import { useQuery } from '@apollo/client'
import MenuQuery from './MenuQuery'

const MenuModal = (props) => {
  const {loading, data} = useQuery(MenuQuery)

  return data ? (
    <>
      {/* MAP THUMBS */}
      { data.allMenuItems.map((item, index)=>(
          <div key={item.id+item.title} className="portfolio-modal modal fade" id={"portfolioModal"+index} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                    <h2 className="text-uppercase">{item.title}</h2>
                                    <p className="item-intro text-muted">{item.description}</p>
                                    { item.closeup ?
                                      <img className="img-fluid d-block mx-auto" src={`${(item.closeup.publicUrl)}`} alt={item.title} /> : null
                                    } 
                                    { item.full ? 
                                      <img className="img-fluid d-block mx-auto" src={`${(item.full.publicUrl)}`} alt={item.title} /> : null
                                    }
                                    <p>{item.description}</p>
                                    <ul className="list-inline">
                                        <li>Status: in stock</li>
                                        <li>MSRP: ${item.msrp} { item.category === "flower" ? "/lb" : "/g" }</li>
                                        <li>Wholesale: ${item.wholesale}/g</li>
                                        <li>Category: {item.category}</li>
                                    </ul>
                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      )) }
    </>
  ) : (
    null
  )
}

export default MenuModal